import React from "react";

interface Prop {
  icon: string;
  label: string;
  infoIcon: string;
}

const ConsentItem = ({ icon = '', label = "label", infoIcon = '' }: Prop) => {
  return (
    <div className="flex items-center w-full border-b-2 border-gray-300 p-4">
      <div className="flex justify-center w-12"><img className="w-6" src={icon} /></div>
      <div className="flex-1 p-1 pl-2 text-gray-600 text-sm">{ label }</div>
      <div className="flex justify-end w-10"><img className="h-4" src={infoIcon} /></div>
    </div>
  );
};

export default ConsentItem;
