import React from "react"
import { window } from "browser-monads"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { authAjax, getQueryParam } from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import { FancyCardHeader } from "../components/FancyInput"
import { navigate } from "gatsby"
import ConsentItem from "../components/ConsentItem";
import RrIcon from "../assets/img/rr-icon.png";
import ProfileIcon from "../assets/img/profile.png";
import EmailIcon from "../assets/img/email.png";
import InfoIcon from "../assets/img/info.png";

const { authURL } = window;

const CONSENT_STATE = {
  /**
   * Initial state, waiting for consent state from backend
   */
  INIT: 'init',
  /**
   * Ask user whether he / she wants to give consent to the requesting client
   */
  SHOW_CONSENT: 'show_consent',
  /**
   * Redirect user automatically to the requesting client because 'Remember consent' is ticked
   */
  REDIRECTING: 'redirecting',
  /**
   * Error in fetching consent state from backend
   */
  ERROR: 'error',
};

const consentFormID = 'consentForm';

class ConsentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: CONSENT_STATE.INIT,
      authResponse: {},
      consent_challenge: getQueryParam("consent_challenge"),
      /**
       * Currently this does not mean anything, but need to be sent as part of the scopes set in hydra specifications
       */
      scope: ["openid", "offline", "offline_access", "profile", "email"],
    }
  }

  componentDidMount() {
    this.getConsentDetail();
  }

  getConsentDetail = () => {
    const { consent_challenge } = this.state;
    authAjax({
      path: `user/openid/client/consent?consent_challenge=${consent_challenge}`,
      type: "GET",
      success: (data, status, xhr) => {
        /*
         * Set the default state to show the consent page. The backend does return
         * a HTTP 100 response, however, it isn't propagated all the way. If skip
         * is set to false in the response, then the subsequent check will be skipped
         */
        this.setState({ status: CONSENT_STATE.SHOW_CONSENT, authResponse: data });

        /*
         * If response is 200 and data.data.skip is not false, it means the backend still remembers the user's last consent
         * so we automatically redirect it back to the application without showing the consent UI
         */
        if (status === 200 && data?.data?.skip !== "false") {
          this.setState({ status: CONSENT_STATE.REDIRECTING, authResponse: data });
          setTimeout(this.autoSubmitConsent.bind(this), 3000);
        }
      },
      error: () => {
        this.sendToError();
      },
    })
  }

  sendToError = () => {
    this.setState({ status: CONSENT_STATE.ERROR });
    /**
     * At the moment we always navigate to login page, we might want to navigate to redirectUri instead.
     * 
     * The issue is there is no guarantee that the UI has access to redirectUri when error occurs, hence why the current handling.
     */
    setTimeout(() => navigate(`/login/`), 3000);
  }

  autoSubmitConsent = () => {
    const consentForm = document.getElementById(consentFormID);
    HTMLFormElement.prototype.submit.call(consentForm);
  }

  render() {
    const { status, authResponse } = this.state;
    const clientName = authResponse?.data?.client_name ?? 'this application';

    return (
      <Layout bgimage dark stickyFooter>
        <SEO title="Consent" />
        {/* Setting responsive to true causes distorted height in certain dimensions, hence set to false for this component */}
        <CenteredContainer responsive={false}>
          <FancyCardHeader title="OAuth Client Consent" padBottom={false} ></FancyCardHeader>
          {
            status === CONSENT_STATE.INIT &&
            <div>
              <p className="subText">
                Waiting for OAuth Client...
              </p>
            </div>
          }
          {
            status === CONSENT_STATE.ERROR &&
            <div>
              <p className="subText">
                Unable to get response from OAuth Client.
                <br /><br />
                Redirecting back to login page...
              </p>
            </div>
          }
          {
            status === CONSENT_STATE.REDIRECTING &&
            <div>
              <p className="subText">
                Login success!
                <br /><br />
                Redirecting back to application...
              </p>
            </div>
          }
          {
            status === CONSENT_STATE.SHOW_CONSENT &&
            <div>
              <p className="subText mb-6 text-sm">
                You are granting <b>{clientName}</b> access to:
              </p>
              <div className="flex flex-col items-center border-t-2 border-gray-300">
                <ConsentItem icon={ProfileIcon} label={'View your basic profile info'} infoIcon={InfoIcon} />
                <ConsentItem icon={EmailIcon} label={'View your email address'} infoIcon={InfoIcon} />
                <ConsentItem icon={RrIcon} label={'Access to your rapyuta.io token'} infoIcon={InfoIcon} />
              </div>
            </div>
          }
          {/* NOTE: form needs to wrapped by <p></p> or the authURL will not parse, not sure why */}
          <p>
            <form
              id={consentFormID}
              action={`${authURL}user/openid/client/consent`}
              method="POST"
              hidden={
                /**
                 * In other status, form is present but hidden. Form's presence is used for autoSubmitConsent
                 */
                status !== CONSENT_STATE.SHOW_CONSENT
              }
            >
              <input type="hidden" name="challenge" value={this.state.consent_challenge} />
              <input type="hidden" name="grant_scope" value={this.state.scope} />
              <div className="flex mb-3 mt-6">
                <label className="inline-flex cursor-pointer">
                  {/* Remember me is not implemented in the backend yet (currently it auto remembers), leaving it here for now */}
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    className="form-checkbox border-0 rounded text-blueGray-800 ml-1 w-5 h-5 cursor-pointer"
                    style={{ transition: "all .15s ease" }}
                    defaultChecked={true}
                  />
                  <span className="ml-2 text-sm font-semibold text-blueGray-700 select-none">
                    Remember consent
                  </span>
                </label>
              </div>
              <div className="flex">
                <button id="submitButton"
                  className="bg-gray-900 text-white active:bg-blueGray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mt-1 w-full"
                  type="submit"
                  name="submit"
                  value="allow"
                  style={{ transition: "all .15s ease" }}
                >
                  Allow Access
                </button>
                <button id="submitButton"
                  className="bg-red-600 text-white active:bg-blueGray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mt-1 w-full"
                  type="submit"
                  name="submit"
                  value="deny"
                  style={{ transition: "all .15s ease" }}
                >
                  Deny Access
                </button>
              </div>
            </form>
          </p>
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ConsentPage
